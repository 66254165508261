import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTranslationEditorContext } from 'providers/TranslationEditor';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import TranslationSpan from './ui/TranslationSpan';

import { LINKS, BUILDER_TEMPLATES_LINKS } from 'constants/constants';
import { canonicalLink } from 'utils/seo';
import LandingLayout from 'containers/layouts/LandingLayout';
import { LandingContentContainer } from 'containers/layouts/LandingLayout/styled';
import styled from '@emotion/styled';
import { mediaMin, breakpoints } from 'styles';

export const ErrorContainer = styled.div`
  margin-top: 20px;

  ${mediaMin(breakpoints.tabletL)} {
    margin-top: 50px;
  }
`;

export const ErrorContent = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ErrorImage = styled.img`
  height: auto;
  max-width: 100%;
`;
export const ErrorCallout = styled.span`
  font-size: 26px;
  font-weight: 700;
  line-height: 1;

  ${mediaMin(breakpoints.tabletL)} {
    font-size: 34px;
  }
`;

export const ErrorTitle = styled.h1`
  font-size: 26px;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 22px;

  ${mediaMin(breakpoints.tabletL)} {
    font-size: 34px;
  }
`;

export const ErrorDescription = styled.p`
  font-size: 16px;

  ${mediaMin(breakpoints.tabletXS)} {
    font-size: 22px;
  }
`;

export const ErrorCTA = styled.a`
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  padding: 12px 30px;
  margin-top: 30px;
  background: var(--qr-global-color-yellow);
  text-decoration: none;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--qr-global-color-white);
  user-select: none;
  border-radius: 6px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background: var(--qr-global-color-yellow-hover);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  ${mediaMin(breakpoints.tabletXS)} {
    font-size: 22px;
    width: auto;
    padding: 12px 55px;
  }
`;

export const QRCodesContainer = styled.div`
  background: red;
  margin-top: 60px;
  margin: 60px 20px 37px;
  padding: 35px 20px 25px;
  background: var(--qr-global-color-main-background);
  border-radius: 30px;
  text-align: center;

  ${mediaMin(breakpoints.tabletXS)} {
    padding: 65px 30px 55px;
  }
  ${mediaMin(breakpoints.laptopM)} {
    margin: 120px 80px -20px;
  }
`;

export const QRCodesTitle = styled.h2`
  font-size: var(--wp--preset--font-size--header-large);
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 40px;
  font-size: 22px;
`;

export const QRCodesWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  gap: 10px;

  ${mediaMin(breakpoints.tabletXS)} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaMin(breakpoints.tabletL)} {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    max-width: 1196px;
  }
`;

export const QRCodeLink = styled.h3`
  padding: 6px 8px;
  background: var(--qr-global-color-blue);
  border-radius: 6px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background: var(--qr-global-color-blue-hover);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  a {
    height: 100%;
    line-height: 1.3;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    color: var(--qr-global-color-white);
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    padding: 6px 20px;
  }
`;

export const ErrorPage403 = () => {
  const { t, i18n } = useTranslation();
  const { getDataAttributes } = useTranslationEditorContext();

  const title = t('qr.errorpages.403.title', '403 – forbidden');
  const description = t(
    'qr.errorpages.403.description',
    'We are sorry, but you do not have access to this page or resource.'
  );

  const cta = (
    <ErrorCTA href={canonicalLink('', false, i18n.language)}>{t('qr.errorpages.403.cta', 'Go to Homepage')}</ErrorCTA>
  );

  return (
    <>
      <NextSeo title={title} description={description} />
      <LandingLayout keepWidth isLogged={false} heightAuto>
        <LandingContentContainer>
          <ErrorContainer>
            <ErrorContent>
              <ErrorImage src="/images/errors/403.svg" alt={title} width="307" height="224" />
              <ErrorCallout {...getDataAttributes('qr.errorpages.callout')}>
                {t('qr.errorpages.callout', 'Oops…')}
              </ErrorCallout>
              <ErrorTitle {...getDataAttributes('qr.errorpages.403.title')}>{title}</ErrorTitle>
              <ErrorDescription>{description}</ErrorDescription>
              {cta}
            </ErrorContent>
          </ErrorContainer>
        </LandingContentContainer>
        <QRCodesContainer>
          <QRCodesTitle>
            <TranslationSpan phrase="qr.errorpages.403.qrcodestitle" />
          </QRCodesTitle>
          <BottomNavigation />
        </QRCodesContainer>
      </LandingLayout>
    </>
  );
};

export const ErrorPage404: FC<{ isLogged?: boolean }> = (props) => {
  const { t, i18n } = useTranslation();
  const { getDataAttributes } = useTranslationEditorContext();

  const title = t('qr.errorpages.404.title', '404 – page not found');
  const description = t('qr.errorpages.404.description', 'The page you are looking for might have been removed.');

  const cta = props.isLogged ? (
    <ErrorCTA href={LINKS.CABINET_QR_CODES}>{t('qr.errorpages.404.ctaloggedin', 'Go to Dashboard')}</ErrorCTA>
  ) : (
    <ErrorCTA href={canonicalLink('', false, i18n.language)}>{t('qr.errorpages.404.cta', 'Go to Homepage')}</ErrorCTA>
  );

  return (
    <>
      <NextSeo title={title} description={description} />
      <LandingLayout keepWidth isLogged={props.isLogged} heightAuto>
        <LandingContentContainer>
          <ErrorContainer>
            <ErrorContent>
              <ErrorImage src="/images/errors/404.svg" alt={title} width="307" height="224" />
              <ErrorCallout {...getDataAttributes('qr.errorpages.callout')}>
                {t('qr.errorpages.callout', 'Oops…')}
              </ErrorCallout>
              <ErrorTitle>{title}</ErrorTitle>
              <ErrorDescription>{description}</ErrorDescription>
              {cta}
            </ErrorContent>
          </ErrorContainer>
        </LandingContentContainer>
        <QRCodesContainer>
          <QRCodesTitle>
            <TranslationSpan phrase="qr.errorpages.404.qrcodestitle" />
          </QRCodesTitle>
          <BottomNavigation />
        </QRCodesContainer>
      </LandingLayout>
    </>
  );
};

export const BottomNavigation = () => {
  const { getDataAttributes } = useTranslationEditorContext();
  const { t } = useTranslation();

  return (
    <QRCodesWrapper>
      <QRCodeLink>
        <Link href={BUILDER_TEMPLATES_LINKS.URL} {...getDataAttributes('qr.errorpages.qrcode1text')} rel="nofollow">
          {t('qr.errorpages.qrcode1text', 'QR code generator for URLs')}
        </Link>
      </QRCodeLink>
      <QRCodeLink>
        <Link
          href={BUILDER_TEMPLATES_LINKS.BUSINESS_PAGE}
          {...getDataAttributes('qr.errorpages.qrcode2text')}
          rel="nofollow"
        >
          {t('qr.errorpages.qrcode2text', 'QR code generator for Business cards')}
        </Link>
      </QRCodeLink>
      <QRCodeLink>
        <Link
          href={BUILDER_TEMPLATES_LINKS.FACEBOOK}
          {...getDataAttributes('qr.errorpages.qrcode3text')}
          rel="nofollow"
        >
          {t('qr.errorpages.qrcode3text', 'QR code generator for Facebook')}
        </Link>
      </QRCodeLink>
      <QRCodeLink>
        <Link href={BUILDER_TEMPLATES_LINKS.WIFI} {...getDataAttributes('qr.errorpages.qrcode4text')} rel="nofollow">
          {t('qr.errorpages.qrcode4text', 'QR code generator for WiFi')}
        </Link>
      </QRCodeLink>
    </QRCodesWrapper>
  );
};
