import { useEffect } from 'react';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'store/auth/selectors';
import { getUser } from 'store/auth';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorPage404 } from 'components/ErrorPage';
import { canonicalLink, languageAlternatesLinks } from 'utils/seo';
import { LINKS } from 'constants/constants';
import { GetStaticProps } from 'next';
import i18n from 'config/i18n';
import { useRouter } from 'next/router';

export const getStaticProps: GetStaticProps = async (props) => {
  await i18n.changeLanguage(props.locale);

  return {
    props,
  };
};

export default function TestPage() {
  const { t, i18n } = useTranslation();
  const { locales } = useRouter();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return (
    <>
      <NextSeo
        title={t('qr.page.landing.seo.title', 'QR Code Generator:  Turn any link, vCard or file into a QR code')}
        description={t(
          'qr.page.landing.seo.description',
          'QR Code Generator: convert any link, vCard, facebook, image or video into a QR code, create QR restaurant menu, track QR code scans, customize design'
        )}
        languageAlternates={languageAlternatesLinks(LINKS.NOT_FOUND, locales)}
        canonical={canonicalLink(LINKS.NOT_FOUND, false, i18n.language)}
      />
      <ErrorPage404 isLogged={!!user} />
    </>
  );
}
